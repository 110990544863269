import { lazy } from 'react';
import { PrivateRoute } from './PrivateRoute';
import { Route } from 'react-router-dom';

const WellnessDashboard = lazy(() => import('../components/Wellness/Dashboard'));
const WellnessClaims = lazy(() => import('../components/Wellness/Claims'));
const WellnessClaimDetails = lazy(() => import('../components/Wellness/Claims/Details'));
const WellnessAppointments = lazy(() => import('../components/Wellness/Appointments/Index'));
const WellnessSupport = lazy(() => import('../components/Wellness/Support'));
const Profile = lazy(() => import('../components/Profile'));
const Notifications = lazy(() => import('../components/Notifications'));
const Drugs = lazy(() => import('../components/Drugs'));

export const wellness = (
  <>
    <Route
      path="/"
      element={
        <PrivateRoute>
          <WellnessDashboard />
        </PrivateRoute>
      }
    />
    <Route
      path="/claims"
      element={
        <PrivateRoute>
          <WellnessClaims />
        </PrivateRoute>
      }
    />
    <Route
      path="/claims/:claim_id"
      element={
        <PrivateRoute>
          <WellnessClaimDetails />
        </PrivateRoute>
      }
    />
    <Route
      path="/appointments"
      element={
        <PrivateRoute>
          <WellnessAppointments />
        </PrivateRoute>
      }
    />

    <Route
      path="/support"
      element={
        <PrivateRoute>
          <WellnessSupport />
        </PrivateRoute>
      }
    />

    <Route
      path="/profile"
      element={
        <PrivateRoute>
          <Profile />
        </PrivateRoute>
      }
    />
    <Route
      path="/notifications"
      element={
        <PrivateRoute>
          <Notifications />{' '}
        </PrivateRoute>
      }
    />
    <Route
      path="/drugs"
      element={
        <PrivateRoute>
          <Drugs />
        </PrivateRoute>
      }
    />
  </>
);
