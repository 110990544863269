import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { baseUrl } from '../utils';
import axios from 'axios';

const initialState = {
  loading: false,

  doctorsList: [],
};

export const getDoctorsList = createAsyncThunk('getDoctorsList', async () => {
  let token = localStorage.getItem('x-auth-token');
  try {
    const response = await axios.get(`${baseUrl}/provider/visit/search-doctor`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
});

export const doctorSlice = createSlice({
  name: 'doctorSlice',
  initialState: initialState,
  extraReducers(builder) {
    // getDoctorsList
    builder.addCase(getDoctorsList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getDoctorsList.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload?.result) {
        state.doctorsList = action?.payload?.result[0];
      } else {
        state.doctorsList = [];
      }
    });
    builder.addCase(getDoctorsList.rejected, (state) => {
      state.loading = false;
      state.doctorsList = [];
    });
  },
});

export default doctorSlice.reducer;
