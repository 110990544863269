import { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import LoaderComponenet from '../components/Common/Loader';

import { PrivateRoute } from './PrivateRoute';
import { hospitals } from './HospitalFiles';
import { hospitalsWithScreening } from './hospitalsWithScreeningRoutes';
import { pharmacy } from './PharmacyFiles';
import { wellness } from './WellnessFiles';
import { screening } from './ScreeningRoutes';

const Welcome = lazy(() => import('../components/Welcome'));
const RestPasswordReq = lazy(() => import('../components/ResetPasswordReq'));
const Login = lazy(() => import('../components/Login'));
const RestPassword = lazy(() => import('../components/ResetPassword'));
const NotFound = lazy(() => import('../components/404'));

const RouteSetup = () => {
  let providerUser = JSON.parse(localStorage.getItem('providerUser'));

  return (
    <Suspense fallback={<LoaderComponenet />}>
      <Routes>
        {providerUser &&
          (providerUser?.type === 'Pharmacy'
            ? pharmacy
            : providerUser?.type === 'Mental_Health'
            ? wellness
            : providerUser?.type === 'Screening_Provider'
            ? screening
            : // : (providerUser?.screening ? hospitalsWithScreening : hospitals))}
              hospitalsWithScreening)}

        <Route path="/login" element={<Login />} />
        <Route path="/welcome" element={<Welcome />} />
        <Route path="/reset-password/:token" element={<RestPassword />} />
        <Route path="/reset-password-request" element={<RestPasswordReq />} />
        <Route
          path="*"
          element={
            <PrivateRoute>
              <NotFound />
            </PrivateRoute>
          }
        />
      </Routes>
    </Suspense>
  );
};

export default RouteSetup;
