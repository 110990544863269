import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { baseUrl } from '../utils';
import axios from 'axios';

const initialState = {
  loading: false,

  data: null,
  content: [],
};

export const getDrugs = createAsyncThunk('getDrugs', async (pagination) => {
  let token = localStorage.getItem('x-auth-token');
  try {
    const response = await axios.get(
      `${baseUrl}/provider/rx/drug-list?page=${pagination?.page}&size=${pagination?.pageSize}`,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
});

export const getFilteredDrugs = createAsyncThunk('getFilteredDrugs', async (query) => {
  let token = localStorage.getItem('x-auth-token');
  try {
    const response = await axios.get(`${baseUrl}/provider/rx/drug-list?query=${query}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
});

export const drugsSlice = createSlice({
  name: 'prescription',
  initialState: initialState,
  extraReducers(builder) {
    builder.addCase(getDrugs.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getDrugs.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload?.result) {
        state.content = action.payload?.result?.content;
        state.data = action.payload?.result;
      } else {
        state.data = {
          content: [],
        };
      }
    });
    builder.addCase(getDrugs.rejected, (state) => {
      state.loading = false;
      state.data = null;
      state.content = [];
    });
    builder.addCase(getFilteredDrugs.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getFilteredDrugs.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload?.result) {
        state.content = action.payload?.result?.content;
        state.data = action.payload?.result;
      } else {
        state.data = {
          content: [],
        };
      }
    });
    builder.addCase(getFilteredDrugs.rejected, (state) => {
      state.loading = false;
      state.data = null;
      state.content = [];
    });
  },
});

export default drugsSlice.reducer;
