import { lazy } from 'react';
import { PrivateRoute } from './PrivateRoute';
import { Route } from 'react-router-dom';

const ScreeningDashboard = lazy(() => import('../components/Screening/Dashboard'));
const NewScreening = lazy(() => import('../components/Screening/Screenings/NewScreening/NewScreening'));
const AddData = lazy(() => import('../components/Screening/Screenings/NewScreening/AddData'));
const Drugs = lazy(() => import('../components/Drugs'));

export const screening = (
  <>
    <Route
      path="/"
      element={
        <PrivateRoute>
          <ScreeningDashboard />
        </PrivateRoute>
      }
    />
    <Route
      path="/new-screening"
      element={
        <PrivateRoute>
          <NewScreening />
        </PrivateRoute>
      }
    />
    <Route
      path="/new-screening/add-data"
      element={
        <PrivateRoute>
          <AddData />
        </PrivateRoute>
      }
    />
    <Route
      path="/drugs"
      element={
        <PrivateRoute>
          <Drugs />
        </PrivateRoute>
      }
    />
  </>
);
