import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { baseUrl } from '../utils';
import axios from 'axios';

const initialState = {
  loading: false,
  data: {
    content: [],
  },
  openVisit: {
    content: [],
  },
  preAuthDetails: {
    timeline: [],
  },
};

export const getVisitsPreAuth = createAsyncThunk('getVisits', async (pagination) => {
  let token = localStorage.getItem('x-auth-token');
  try {
    const response = await axios.get(
      `${baseUrl}/provider/visit?status=Open&sort=visitCreatedOn,desc&screen=PreAuth&page=${pagination?.page}&size=${pagination?.pageSize}`,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
});

export const newPreAuth = createAsyncThunk(
  'newPreAuth/new',
  async ({ updatedValue, openNotificationWithIcon, navigate, setLoading }) => {
    let token = localStorage.getItem('x-auth-token');
    setLoading(true);
    try {
      const { data } = await axios.post(`${baseUrl}/provider/pre-authorisation`, updatedValue, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      console.log('data==', data);
      if (data?.api?.responseCode === 2010) {
        openNotificationWithIcon('success', data.message);
        navigate(`/pre-auths/${data?.result?.pre_authorisation_id}`);
      } else {
        openNotificationWithIcon('error', data.message);
      }
      setLoading(false);
      return data;
    } catch (error) {
      openNotificationWithIcon('error', error.message);
      console.log(error);
      setLoading(false);
    }
  },
);

export const getSubmittedPreAuth = createAsyncThunk('getSubmittedPreAuth', async (pagination) => {
  let token = localStorage.getItem('x-auth-token');

  try {
    const response = await axios.get(
      `${baseUrl}/provider/pre-authorisation?sort=createdTimestamp,desc&page=${pagination?.page}&size=${pagination?.pageSize}`,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      },
    );

    return response.data;
  } catch (error) {
    console.log(error);
  }
});

export const getSubmittedPreAuthWithFilter = createAsyncThunk(
  'getSubmittedPreAuthWithFilter',
  async ({ page, status }) => {
    let token = localStorage.getItem('x-auth-token');

    try {
      const response = await axios.get(
        `${baseUrl}/provider/pre-authorisation/?sort=createdTimestamp,desc&page=${page}&size=${6}&status=${status}`,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        },
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  },
);

export const searchSubmittedPreAuth = createAsyncThunk(
  'searchSubmittedPreAuth',
  async ({ page, query }) => {
    let token = localStorage.getItem('x-auth-token');
    try {
      const response = await axios.get(
        `${baseUrl}/provider/pre-authorisation/search?query=${query}`,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        },
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  },
);

export const getPreAuthDetail = createAsyncThunk('preAuthDetail', async ({ id, navigate, openNotificationWithIcon }, thunkAPI) => {
  return await fetch(`${baseUrl}/provider/pre-authorisation/${id}/status/timeline`, {
    method: 'GET',
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${localStorage.getItem('x-auth-token')}`,
    }
  })
    .then(async res => {
      return res.json().then(resJson => {
        if (!res.ok) throw new Error(resJson?.error)
        if (resJson?.api?.responseCode !== 2040) {
          throw new Error(resJson?.message)
        } else {
          return resJson
        }
      })
    })
    .catch(e => { openNotificationWithIcon('error', e.message || 'Unknown Error'); navigate('/pre-auths/submitted') })
});



export const searchPreAuth = createAsyncThunk('searchPreAuth', async (searchItem) => {
  let token = localStorage.getItem('x-auth-token');

  const searchObj = {};
  if (searchItem.search) {
    const key = Object.keys(searchItem.search);
    if (key[0] === 'PreAuth ID') {
      searchObj.preAuthorisationId = Object.values(searchItem.search)[0];
    } else if (key[0] === 'Member No.') {
      searchObj.memberNumber = Object.values(searchItem.search)[0];
    } else if (key[0] === 'Patient Name') {
      searchObj.patientName = Object.values(searchItem.search)[0];
    }
  }
  let filterStr = '';
  if (searchItem.filters) {
    const keys = Object.keys(searchItem.filters);
    if (keys.indexOf('startDate') !== -1) {
      filterStr = filterStr + `&fromDate=${searchItem.filters['startDate']}`;
    }
    if (keys.indexOf('endDate') !== -1) {
      filterStr = filterStr + `&toDate=${searchItem.filters['endDate']}`;
    }
    if (keys.indexOf('doctor') !== -1) {
      filterStr = filterStr + `&attendingDoctorName=${searchItem.filters['doctor']}`;
    }
    if (keys.indexOf('status') !== -1) {
      filterStr = filterStr + `&status=${searchItem.filters['status']}`;
    }
  }

  try {
    const response = await axios.get(
      `${baseUrl}/provider/pre-authorisation/search?${Object.keys(searchObj).length > 0
        ? `${Object.keys(searchObj)[0]}=${Object.values(searchObj)[0]} `
        : ''
      }${filterStr}`,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
});

export const searchOpenPreauth = createAsyncThunk('searchOpenPreauth', async (searchItem) => {
  let token = localStorage.getItem('x-auth-token');
  const key = Object.keys(searchItem);
  const searchObj = {};
  if (searchItem.search) {
    const key = Object.keys(searchItem.search);
    if (key[0] === 'Visit ID') {
      searchObj.visitId = Object.values(searchItem.search)[0];
    } else if (key[0] === 'Member No.') {
      searchObj.memberNumber = Object.values(searchItem.search)[0];
    } else if (key[0] === 'Patient Name') {
      searchObj.memberName = Object.values(searchItem.search)[0];
    }
  }

  console.log(searchItem.filters);
  let filterStr = '';
  if (searchItem.filters) {
    const keys = Object.keys(searchItem.filters);
    if (keys.indexOf('startDate') !== -1) {
      filterStr = filterStr + `&fromDate=${searchItem.filters['startDate']}`;
    }
    if (keys.indexOf('endDate') !== -1) {
      filterStr = filterStr + `&toDate=${searchItem.filters['endDate']}`;
    }
    if (keys.indexOf('doctor') !== -1) {
      filterStr = filterStr + `&attendingDoctorName=${searchItem.filters['doctor']}`;
    }
  }

  try {
    const response = await axios.get(
      `${baseUrl}/provider/visit/search-visit?status=Open&screen=PreAuth&${Object.keys(searchObj).length > 0
        ? `${Object.keys(searchObj)[0]}=${Object.values(searchObj)[0]} `
        : ''
      }${filterStr}`,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
});

export const PreAuthSlice = createSlice({
  name: 'preAuths',
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    //AsyncReducers start here
    //Get Pre-auth
    builder.addCase(getVisitsPreAuth.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getVisitsPreAuth.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload?.result) {
        state.openVisit = action.payload?.result;
      } else {
        state.openVisit = {
          content: [],
        };
      }
    });
    builder.addCase(getVisitsPreAuth.rejected, (state) => {
      state.loading = false;
      state.openVisit = {
        content: [],
      };
    });
    builder.addCase(getSubmittedPreAuth.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getSubmittedPreAuth.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload?.result) {
        state.data = action.payload?.result;
      } else {
        state.data = {
          content: [],
        };
      }
    });
    builder.addCase(getSubmittedPreAuth.rejected, (state) => {
      state.loading = false;
      state.data = {
        content: [],
      };
    });
    builder.addCase(getPreAuthDetail.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getPreAuthDetail.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload?.result) {
        state.preAuthDetails = action.payload?.result;
      } else {
        state.preAuthDetails = {
          content: [],
        };
      }
    });
    builder.addCase(getPreAuthDetail.rejected, (state) => {
      state.loading = false;
      state.preAuthDetails = {
        content: [],
      };
    });
    builder.addCase(getSubmittedPreAuthWithFilter.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getSubmittedPreAuthWithFilter.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload?.result) {
        state.data = action.payload?.result;
      } else {
        state.data = {
          content: [],
        };
      }
    });
    builder.addCase(getSubmittedPreAuthWithFilter.rejected, (state) => {
      state.loading = false;
      state.data = {
        content: [],
      };
    });
    builder.addCase(searchSubmittedPreAuth.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(searchSubmittedPreAuth.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload?.result) {
        state.data.content = action.payload?.result;
        state.data.property = null;
      } else {
        state.data = {
          content: [],
        };
      }
    });
    builder.addCase(searchSubmittedPreAuth.rejected, (state) => {
      state.loading = false;
      state.data = {
        content: [],
      };
    });
    // search preAuth
    builder.addCase(searchPreAuth.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(searchPreAuth.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload?.result) {
        state.data = action.payload?.result;
      } else {
        state.data = {
          content: [],
        };
      }
    });
    builder.addCase(searchPreAuth.rejected, (state) => {
      state.loading = false;
      state.data = {
        content: [],
      };
    });

    // searchOpenPreauth
    builder.addCase(searchOpenPreauth.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(searchOpenPreauth.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload?.result) {
        state.openVisit = action.payload?.result;
      } else {
        state.openVisit = {
          content: [],
        };
      }
    });
    builder.addCase(searchOpenPreauth.rejected, (state) => {
      state.loading = false;
      state.data = {
        content: [],
      };
    });
  },
});

// export const {} = PreAuthSlice.actions;

export default PreAuthSlice.reducer;
